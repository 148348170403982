import { ReactQueryKey } from '@enums';
import { ActionsOrderBy, PrivilegedTasksOrderBy } from '@generated/types/graphql';
import { useActionItemList } from '@hooks/actionItems/useActionItemList';
import { useWorkOrderList } from '@hooks/workOrders/useWorkOrderList';
import { useMemo } from 'react';

export const useWork = (projectId: number, isArchivedShown: boolean) => {
  const { data: workOrders = [] } = useWorkOrderList({
    queryKey: ReactQueryKey.ProjectWork,
    filters: { projectId: { equalTo: projectId }, isArchived: isArchivedShown ? {} : { equalTo: false } },
    orderBy: [
      PrivilegedTasksOrderBy.IsArchivedAsc,
      PrivilegedTasksOrderBy.PriorityAsc,
      PrivilegedTasksOrderBy.CreatedAtAsc
    ]
  });
  const { data: actionItems = [] } = useActionItemList(projectId, ActionsOrderBy.CreatedAtAsc);

  return useMemo(
    () => [...actionItems, ...workOrders.filter((workOrder) => isArchivedShown || !workOrder.isArchived)],
    [actionItems, workOrders, isArchivedShown]
  );
};

export const useWorkGroupedByStage = (projectId: number, isArchivedShown: boolean) => {
  const work = useWork(projectId, isArchivedShown);

  return useMemo(() => {
    return work.reduce(
      (acc, workItem) => {
        const stageId = workItem.projectStage?.id ?? -1;

        if (!acc[stageId]) {
          acc[stageId] = [];
        }
        acc[stageId].push(workItem);

        return acc;
      },
      {} as Record<number, typeof work>
    );
  }, [work]);
};
