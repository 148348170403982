import React from 'react';
import { AtSign, Clock, Monitor, Sunset, Tool } from 'react-feather';
import { Contact, CreatedSource, EntityType, EventType, FeedInbox, User } from '@generated/types/graphql';
import { transformCommentText } from '@utils/comment';
import { RecordType } from '@types';

import { EntityType as EventEntityType, ProjectEventType, ReminderType } from '@enums';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { AccountIcon } from '@common/icons/Account';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { REMINDER_TYPE_TO_ICON } from '@common/Reminder/ReminderTypeSelector';
import { parseUtcDate } from '@utils/dates';
import moment from 'moment';
import { IncomingEmailIcon } from '@kit/ui/icons/IncomingEmail';
import { cleanHtml } from '@utils/utils';
import { SmsIcon } from '@kit/ui/icons/Sms';
import { PortalIcon } from '@kit/ui/icons/Portal';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { NotficationEntity, NotificationEntityType } from './types';
import { EmailBody } from './styled';

type NotificationInfo = {
  icon: React.ReactNode;
  author?: User;
  contact?: Contact;
  content?: string;
  contentNode?: React.ReactNode;
  entity: NotficationEntity | null;
  createdAt: string;
  feedCursor?: string;
  badge?: string;
};

const MAP_RECORD_TYPE_TO_NOTIFICATION_ENTITY_TYPE: Record<string, NotificationEntityType> = {
  [RecordType.PROJECT]: NotificationEntityType.Project,
  [RecordType.ACCOUNT]: NotificationEntityType.Client,
  [RecordType.DEAL]: NotificationEntityType.Request
};

const getNotificationIcon = (notification: FeedInbox): React.ReactNode => {
  switch (notification.entityType) {
    case EntityType.Communication: {
      switch (notification.eventType) {
        case EventType.MentionsAnywhere: {
          return <AtSign size="16px" color="#9C9CAA" />;
        }
        case EventType.PortalMessagesReceived: {
          return <PortalIcon size="16px" color="#FFBD13" />;
        }
        case EventType.EmailReceived: {
          return <IncomingEmailIcon size="16px" color="#9C9CAA" />;
        }
        case EventType.SmsReceived: {
          return <SmsIcon size="16px" color="#9C9CAA" />;
        }
        case EventType.PortalDocumentUploaded: {
          return <FileUpIcon size="16px" color="#9C9CAA" />;
        }
        default:
          return null;
      }
    }
    case EntityType.Request: {
      return <DollarIcon size="16px" color="#9C9CAA" />;
    }

    case EntityType.Project: {
      return <BriefcaseIcon size="16px" color="#9C9CAA" />;
    }

    case EntityType.Client: {
      return <AccountIcon size={16} color="#9C9CAA" />;
    }

    case EntityType.WorkOrder: {
      const isField = notification.feed.task?.isField;

      return isField ? <Tool size="16px" color="#9C9CAA" /> : <Monitor size="16px" color="#9C9CAA" />;
    }

    case EntityType.Reminder: {
      const type = notification.feed.reminder?.type;
      if (!type) {
        return <Clock size="16px" color="#9C9CAA" />;
      }

      const Icon = REMINDER_TYPE_TO_ICON[type as ReminderType];

      return <Icon size="16px" color="#9C9CAA" />;
    }

    case EntityType.Action: {
      return <FileUpIcon size="16px" color="#9C9CAA" />;
    }

    case EntityType.Fleet:
      return <Sunset size="16px" color="#9C9CAA" />;

    default:
      return null;
  }
};

const getNotficationEntity = (notification: FeedInbox): NotficationEntity | null => {
  if (notification.feed.eventType === ProjectEventType.COMMENTED) {
    switch (notification.feed.event) {
      case EventEntityType.FILE: {
        return {
          type: NotificationEntityType.File,
          id: notification.feed.payload?.id,
          title: notification.feed.payload?.name
        };
      }
      case EventEntityType.PROJECT: {
        return {
          type: MAP_RECORD_TYPE_TO_NOTIFICATION_ENTITY_TYPE[notification.feed.project.type],
          id: notification.feed.payload?.id,
          title: notification.feed.payload?.name,
          uid: notification.feed.project?.uid ?? notification.feed.payload?.uid
        };
      }
      case EventEntityType.TASK: {
        return {
          type: NotificationEntityType.WorkOrder,
          id: notification.feed.payload?.id,
          title: notification.feed.payload?.name,
          uid: notification.feed.payload?.uid
        };
      }

      case EventEntityType.SYSTEM: {
        return {
          type: NotificationEntityType.System,
          id: notification.feed.payload?.id,
          title: notification.feed.payload?.name
        };
      }

      default: {
        return null;
      }
    }
  }

  if (notification.eventType === EventType.PortalMessagesReceived) {
    return {
      type: MAP_RECORD_TYPE_TO_NOTIFICATION_ENTITY_TYPE[notification.feed.project.type],
      id: notification.feed.payload?.id,
      title: notification.feed.payload?.name,
      uid: notification.feed.project?.uid
    };
  }

  if (notification.eventType === EventType.PortalDocumentUploaded) {
    return {
      type: MAP_RECORD_TYPE_TO_NOTIFICATION_ENTITY_TYPE[notification.feed.payload?.project?.type],
      id: notification.feed.payload?.project?.id,
      title: notification.feed.payload?.project?.title,
      uid: notification.feed.project?.uid
    };
  }

  return null;
};

const getCommunicationInfo = (notification: FeedInbox): NotificationInfo | null => {
  switch (notification.eventType) {
    case EventType.MentionsAnywhere: {
      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,

        contentNode: (
          <div dangerouslySetInnerHTML={{ __html: transformCommentText(notification.feed.relatedComment?.comment) }} />
        ),
        entity: getNotficationEntity(notification),
        createdAt: notification.createdAt,
        feedCursor: encodeURIComponent(notification.feed.parent?.virtualCreatedAt ?? notification.feed.virtualCreatedAt)
      };
    }

    case EventType.PortalMessagesReceived: {
      return {
        icon: getNotificationIcon(notification),
        contact: notification.feed.createdByContact,
        contentNode: (
          <div dangerouslySetInnerHTML={{ __html: transformCommentText(notification.feed.relatedComment?.comment) }} />
        ),
        entity: getNotficationEntity(notification),
        createdAt: notification.createdAt,
        feedCursor: encodeURIComponent(notification.feed.parent?.virtualCreatedAt ?? notification.feed.virtualCreatedAt)
      };
    }

    case EventType.PortalDocumentUploaded: {
      return {
        icon: getNotificationIcon(notification),
        contact: notification.feed.createdByContact,
        content: `Client uploaded document "${notification.feed.payload?.name}" in Portal`,
        entity: getNotficationEntity(notification),
        createdAt: notification.createdAt
      };
    }

    case EventType.EmailReceived: {
      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        contact: notification.feed.createdByContact,
        contentNode: (
          <div>
            {notification.feed.emailMessage?.subject}{' '}
            <EmailBody> - {cleanHtml(notification.feed.emailMessage.bodyCleaned)}</EmailBody>
          </div>
        ),
        entity: {
          type: MAP_RECORD_TYPE_TO_NOTIFICATION_ENTITY_TYPE[notification.feed.payload?.project?.type],
          id: notification.feed.payload?.project?.id,
          uid: notification.feed.payload?.project?.uid,
          title: notification.feed.payload?.project?.title
        },
        createdAt: notification.createdAt,
        feedCursor: encodeURIComponent(notification.feed.parent?.virtualCreatedAt ?? notification.feed.virtualCreatedAt)
      };
    }
    case EventType.SmsReceived: {
      if (!notification.feed.smsActivity) {
        return null;
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.smsActivity.fromUser,
        contact: notification.feed.smsActivity.fromContact,

        content: notification.feed.smsActivity?.text ?? '',
        entity: {
          type: MAP_RECORD_TYPE_TO_NOTIFICATION_ENTITY_TYPE[notification.feed.payload?.project?.type],
          id: notification.feed.payload?.project?.id,
          uid: notification.feed.payload?.project?.uid,
          title: notification.feed.payload?.project?.title
        },
        createdAt: notification.createdAt,
        feedCursor: encodeURIComponent(notification.feed.virtualCreatedAt)
      };
    }

    default:
      return null;
  }
};

export const getRecordInfo = (
  notification: FeedInbox,
  currentUserId: number,
  entityType: NotificationEntityType
): NotificationInfo | null => {
  switch (notification.eventType) {
    case EventType.RequestAppointmentScheduled: {
      const isRescheduled = Boolean(notification.feed.payload?.previousDueDate);
      const isRemoved = Boolean(notification.feed.payload?.deleted);

      let content;

      if (isRemoved) {
        content = `Today's Appointment "${notification.feed.payload?.name}" at ${moment(parseUtcDate(notification.feed.payload?.previousDueDate)).format('MM/DD/YYYY, hh:mma')} was cancelled`;
      } else {
        content = `Today's Appointment "${notification.feed.payload?.name}" was ${isRescheduled ? 're' : ''}scheduled at ${moment(parseUtcDate(notification.feed.payload?.dueDate)).format('hh:mma')}`;
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        content,
        entity: {
          type: entityType,
          id: notification.feed.payload?.id,
          uid: notification.feed.project?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt
      };
    }
    case EventType.FutureRequestAppointmentScheduled: {
      const isRescheduled = Boolean(notification.feed.payload?.previousDueDate);
      const isRemoved = Boolean(notification.feed.payload?.deleted);

      let content;

      if (isRemoved) {
        content = `Appointment "${notification.feed.payload?.name}" at ${moment(parseUtcDate(notification.feed.payload?.previousDueDate)).format('MM/DD/YYYY, hh:mma')} was cancelled`;
      } else {
        content = `Appointment "${notification.feed.payload?.name}" was ${isRescheduled ? 're' : ''}scheduled at ${moment(parseUtcDate(notification.feed.payload?.dueDate)).format('hh:mma')}`;
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        content,
        entity: {
          type: entityType,
          id: notification.feed.payload?.id,
          uid: notification.feed.project?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt
      };
    }

    case EventType.RequestCreated:
    case EventType.RequestCreatedInPortal:
    case EventType.ReferralSubmitted:
    case EventType.ProjectCreated:
    case EventType.ClientCreated: {
      let badge: string;

      let content = `${entityType} ${notification.feed.project.uid}: ${notification.feed.payload.name} created`;

      if (notification.feed.project?.source !== CreatedSource.Internal) {
        if (notification.feed.project?.source === CreatedSource.SubmitReferral) {
          content = `Referral ${content} through Portal`;
        } else {
          content = `${content} through Portal`;
        }
      }

      if (
        notification.feed.project?.source === CreatedSource.SignupReferral ||
        notification.feed.project?.source === CreatedSource.SubmitReferral
      ) {
        badge = 'Referral';
      } else if (notification.feed.project?.source === CreatedSource.SignupPublic) {
        badge = 'New';
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        contact: notification.feed.createdByContact,
        content,
        entity: {
          type: entityType,
          id: notification.feed.payload?.id,
          uid: notification.feed.project?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt,
        badge
      };
    }
    case EventType.ProjectManagerUpdated:
    case EventType.RequestPmUpdated: {
      const isMe = notification.feed.payload?.addedUser?.id === currentUserId;
      const wasMe = notification.feed.payload?.addedOn?.[0]?.removedUser?.id === currentUserId;

      let content;

      if (isMe) {
        content = `You are assigned to ${entityType} as Project Manager`;
      } else if (wasMe) {
        content = `You are no longer assigned to ${entityType} as Project Manager`;
      } else if (notification.feed.payload?.addedUser) {
        content = `${notification.feed.payload?.addedUser?.name} is assigned to  ${entityType} as Project Manager`;
      } else if (notification.feed.payload?.addedOn?.[0]?.removedUser) {
        content = `${notification.feed.payload?.addedOn?.[0]?.removedUser.name} is no longer assigned to  ${entityType} as Project Manager`;
      } else {
        content = 'Request Project Manager updated';
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        content,
        entity: {
          type: entityType,
          id: notification.feed.payload?.id,
          uid: notification.feed.project?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt
      };
    }

    case EventType.ClientOwnerUpdated:
    case EventType.RequestOwnerUpdated:
    case EventType.ProjectOwnerUpdated: {
      const isMe = notification.feed.payload?.addedUser?.id === currentUserId;
      const wasMe = notification.feed.payload?.addedOn?.[0]?.removedUser?.id === currentUserId;

      let content;

      if (isMe) {
        content = `You are assigned to ${entityType} as Owner`;
      } else if (wasMe) {
        content = `You are no longer assigned to ${entityType} as Owner`;
      } else if (notification.feed.payload?.addedUser) {
        content = `${notification.feed.payload?.addedUser?.name} is assigned to  ${entityType} as Owner`;
      } else if (notification.feed.payload?.addedOn?.[0]?.removedUser) {
        content = `${notification.feed.payload?.addedOn?.[0]?.removedUser.name} is no longer assigned to  ${entityType} as Owner`;
      } else {
        content = 'Request Owner updated';
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        content,
        entity: {
          type: entityType,
          id: notification.feed.payload?.id,
          uid: notification.feed.project?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt
      };
    }

    case EventType.SalesRepUpdated:
    case EventType.SalesRepAssignedOrUpdated: {
      const isMe = notification.feed.payload?.addedUser?.id === currentUserId;
      const wasMe = notification.feed.payload?.addedOn?.[0]?.removedUser?.id === currentUserId;

      let content;

      if (isMe) {
        content = `You are assigned to ${entityType} as Sales Rep`;
      } else if (wasMe) {
        content = `You are no longer assigned to ${entityType} as Sales Rep`;
      } else if (notification.feed.payload?.addedUser) {
        content = `${notification.feed.payload?.addedUser?.name} is assigned to  ${entityType} as Sales Rep`;
      } else if (notification.feed.payload?.addedOn?.[0]?.removedUser) {
        content = `${notification.feed.payload?.addedOn?.[0]?.removedUser.name} is no longer assigned to  ${entityType} as Sales Rep`;
      } else {
        content = 'Request Sales Rep updated';
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        content,
        entity: {
          type: entityType,
          id: notification.feed.payload?.id,
          uid: notification.feed.project?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt
      };
    }

    default: {
      return null;
    }
  }
};

const getWorkOrderInfo = (notification: FeedInbox, currentUserId: number): NotificationInfo | null => {
  switch (notification.eventType) {
    case EventType.AssigneeAddedOrUpdated: {
      const assignee = notification.feed.payload?.assignee ?? {};
      const previousAssignee = notification.feed.payload?.previousAssignee ?? {};
      const isYouPrevious = previousAssignee.id === currentUserId;

      const name = assignee.id === currentUserId ? 'You' : assignee.name;

      const verb = currentUserId === assignee.id ? 'are' : 'is';

      let content;

      if (isYouPrevious) {
        content = `You are no longer assigned to Work Order as Assignee`;
      } else if (name) {
        content = `${name} ${verb} assigned to Work Order as Assignee`;
      } else if (!notification.feed.payload?.assignee && previousAssignee) {
        content = `${previousAssignee.name} is no longer assigned to Work Order as Assignee`;
      } else {
        content = `Assignee was updated on Work Order`;
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        content,
        entity: {
          type: NotificationEntityType.WorkOrder,
          id: notification.feed.payload?.id,
          uid: notification.feed.task?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt
      };
    }
    case EventType.CollaboratorsAddedOrUpdated: {
      const assignees = notification.feed.payload?.assignees ?? [];
      const prevAssignees = notification.feed.payload?.previousAssignees ?? [];

      const added = assignees.filter(
        (assignee) => !prevAssignees.some((prevAssignee) => prevAssignee.id === assignee.id)
      );
      const removed = prevAssignees.filter(
        (prevAssignee) => !assignees.some((assignee) => assignee.id === prevAssignee.id)
      );

      let content;

      if (removed.length === 0 && added.length > 0) {
        let addedNames = [...added]
          .sort((a) => (a.id === currentUserId ? -1 : 1))
          .slice(0, 2)
          .map((assignee) => (assignee.id === currentUserId ? 'You' : assignee.name))
          .join(', ');

        if (added.length > 2) {
          addedNames += ` and ${added.length - 2} more`;
        }

        let verb = added.length > 1 ? 'are' : 'is';

        if (added.length === 1 && added[0].id === currentUserId) {
          verb = 'are';
        }

        content = `${addedNames} ${verb} assigned to Work Order as Collaborator${added.length > 1 ? 's' : ''}`;
      } else if (added.length === 0 && removed.length > 0) {
        let removedNames = [...removed]
          .sort((a) => (a.id === currentUserId ? -1 : 1))
          .slice(0, 2)
          .map((assignee) => (assignee.id === currentUserId ? 'You' : assignee.name))
          .join(', ');

        if (removed.length > 2) {
          removedNames += ` and ${removed.length - 2} more`;
        }

        let verb = removed.length > 1 ? 'are' : 'is';

        if (removed.length === 1 && removed[0].id === currentUserId) {
          verb = 'are';
        }

        content = `${removedNames} ${verb} no longer assigned to Work Order as Collaborator${removed.length > 1 ? 's' : ''}`;
      } else {
        content = `Collaborators for Work Order were updated`;
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        content,
        entity: {
          type: NotificationEntityType.WorkOrder,
          id: notification.feed.payload?.id,
          uid: notification.feed.task?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt
      };
    }
    case EventType.TodaysVisitScheduled: {
      const isRemoved = Boolean(notification.feed.payload?.scheduledVisit?.removed);
      const isRescheduled = notification.feed.payload?.scheduledVisit?.previousVisit;

      let content;

      const time = moment(parseUtcDate(notification.feed.payload?.scheduledVisit?.startDate)).format('hh:mma');

      if (isRemoved) {
        content = `Today's visit for Work Order at ${time}  was cancelled`;
      } else {
        content = `Today's visit for Work Order was ${isRescheduled ? 're' : ''}scheduled at ${time}`;
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        content,
        entity: {
          type: NotificationEntityType.WorkOrder,
          id: notification.feed.payload?.id,
          uid: notification.feed.task?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt
      };
    }
    case EventType.FutureVisitScheduled: {
      const isRemoved = Boolean(notification.feed.payload?.scheduledVisit?.removed);

      const isRescheduled = notification.feed.payload?.scheduledVisit?.previousVisit;

      const dateAndTime = moment(parseUtcDate(notification.feed.payload?.scheduledVisit?.startDate)).format(
        'MM/DD/YYYY, hh:mma'
      );

      let content;

      if (isRemoved) {
        content = `Visit for Work Order at ${dateAndTime}  was cancelled`;
      } else {
        content = `Visit for Work Order was ${isRescheduled ? 're' : ''}scheduled at ${dateAndTime}`;
      }

      return {
        icon: getNotificationIcon(notification),
        author: notification.feed.createdByUser,
        content,
        entity: {
          type: NotificationEntityType.WorkOrder,
          id: notification.feed.payload?.id,
          uid: notification.feed.task?.uid ?? notification.feed.payload.uid,
          title: notification.feed.payload?.name
        },
        createdAt: notification.createdAt
      };
    }
    default:
      return null;
  }
};

export const getReminderInfo = (notification: FeedInbox, currentUserId: number): NotificationInfo | null => {
  const isAppointment = notification.feed.payload?.isAppointment;

  const entityName = isAppointment ? 'Appointment' : 'Reminder';

  const info: NotificationInfo = {
    icon: getNotificationIcon(notification),
    author: notification.feed.createdByUser,
    content: '',
    entity: {
      type: MAP_RECORD_TYPE_TO_NOTIFICATION_ENTITY_TYPE[notification.feed.payload?.project?.type ?? RecordType.PROJECT],
      id: notification.feed.payload?.project?.id,
      uid: notification.feed.project?.uid,
      title: notification.feed.payload?.project?.title
    },
    createdAt: notification.createdAt
  };

  const content = (() => {
    switch (notification.eventType) {
      case EventType.ReminderOverdue:
        return `${entityName} "${notification.feed.payload?.name}" is overdue`;
      case EventType.ReminderAssignedOrReassigned: {
        const assignee = notification.feed.payload?.assignee ?? {};
        const isYouCurrent = assignee.id === currentUserId;

        if (isYouCurrent) {
          return `You are assigned to ${entityName} "${notification.feed.payload?.name}"`;
        } else {
          return `You are no longer assigned to ${entityName} "${notification.feed.payload?.name}"`;
        }
      }
      case EventType.ReminderRescheduled: {
        const isRemoved = Boolean(notification.feed.payload?.deleted);
        const isRescheduled = Boolean(notification.feed.payload?.previousDueDate);

        if (isRemoved) {
          return `${entityName} "${notification.feed.payload?.name}" at ${moment(parseUtcDate(notification.feed.payload?.previousDueDate)).format('MM/DD/YYYY, hh:mma')} was cancelled`;
        }

        return `${entityName} "${notification.feed.payload?.name}" was ${isRescheduled ? 're' : ''}scheduled at ${moment(parseUtcDate(notification.feed.payload?.dueDate)).format('MM/DD/YYYY, hh:mma')}`;
      }
      default:
        return '';
    }
  })();

  info.content = content;

  return info;
};

export const getActionInfo = (notification: FeedInbox): NotificationInfo | null => {
  return {
    icon: getNotificationIcon(notification),
    author: notification.feed.createdByUser,
    contact: notification.feed.createdByContact,
    content: `Document Upload “${notification.feed.payload?.name}“ was sent to review`,
    entity: {
      type: NotificationEntityType.Project,
      id: notification.feed.payload?.project?.id,
      uid: notification.feed.project?.uid,
      title: notification.feed.payload?.project?.title
    },
    createdAt: notification.createdAt
  };
};

export const getSystemInfo = (notification: FeedInbox): NotificationInfo | null => {
  const common = {
    icon: getNotificationIcon(notification),
    author: notification.feed.createdByUser,
    createdAt: notification.createdAt
  };

  switch (notification.eventType) {
    case EventType.BudgetExceeded: {
      return {
        ...common,
        content: `Systems Budget exceeded`,
        entity: null
      };
    }

    case EventType.AlertTriggered: {
      return {
        ...common,
        content: `System Alert “${notification.feed.payload?.name}“ triggered`,
        entity: {
          type: NotificationEntityType.System,
          id: notification.feed.payload?.system?.id,
          title: notification.feed.payload?.system?.name
        }
      };
    }

    default:
      return null;
  }
};

export const getNotificationInfo = (notification: FeedInbox, currentUserId: number): NotificationInfo | null => {
  switch (notification.entityType) {
    case EntityType.Communication:
      return getCommunicationInfo(notification);
    case EntityType.Request:
      return getRecordInfo(notification, currentUserId, NotificationEntityType.Request);
    case EntityType.Project:
      return getRecordInfo(notification, currentUserId, NotificationEntityType.Project);
    case EntityType.Client:
      return getRecordInfo(notification, currentUserId, NotificationEntityType.Client);
    case EntityType.WorkOrder:
      return getWorkOrderInfo(notification, currentUserId);
    case EntityType.Reminder:
      return getReminderInfo(notification, currentUserId);
    case EntityType.Action:
      return getActionInfo(notification);
    case EntityType.Fleet:
      return getSystemInfo(notification);
    default:
      return null;
  }
};
