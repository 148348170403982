import { useRecordDetail } from '@hooks/useRecordDetail';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ActionItemListItem } from '@hooks/actionItems/useActionItemList';
import { ActionStatus, PrivilegedTask, ProjectStage, TaskStatus } from '@generated/types/graphql';
import { Collapse } from '@material-ui/core';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import { WorkItemList } from './WorkItemList';
import { Stage, StageName, WorkListContainer } from './styled';
import { useWorkGroupedByStage } from './useWork';

interface Props {
  projectId: number;
  isArchivedShown: boolean;
}

export const WorkByStages = ({ projectId, isArchivedShown }: Props) => {
  const { data: record } = useRecordDetail(projectId, { refetchOnMount: false });
  const workByStageId = useWorkGroupedByStage(projectId, isArchivedShown);

  const [expandedStageId, setExpandedStageId] = useState<number | null>(record?.stage?.id ?? -1);

  const handleStageClick = useCallback((id: number) => {
    setExpandedStageId((prev) => (prev === id ? null : id));
  }, []);

  if (!record || !record.blueprint) {
    return null;
  }

  return (
    <div>
      {record.blueprint.blueprintProjectStages.map(({ projectStage }, index) => {
        const timelineStage = record.projectStageTimelinesByProjectId.find(
          (timelineItem) => timelineItem.stage.id === projectStage.id
        );
        const isEndedInTimeline = Boolean(timelineStage?.isEnded);
        const isStartedInTimeline = Boolean(timelineStage);

        return (
          <StageWork
            key={projectStage.id}
            index={index}
            work={workByStageId[projectStage.id] ?? []}
            stage={projectStage}
            isExpanded={expandedStageId === projectStage.id}
            onClick={handleStageClick}
            isCurrent={record.stage?.id === projectStage.id}
            isEndedInTimeline={isEndedInTimeline}
            isStartedInTimeline={isStartedInTimeline}
          />
        );
      })}

      {workByStageId[-1] && (
        <StageWork
          index={record.blueprint.blueprintProjectStages.length}
          work={workByStageId[-1]}
          stage={{ id: -1, name: 'No stage' }}
          isExpanded={expandedStageId === -1}
          onClick={handleStageClick}
          isCurrent={!record.stage?.id}
          isEndedInTimeline={false}
          isStartedInTimeline={false}
        />
      )}
    </div>
  );
};

interface StageWorkProps {
  index: number;
  work: (ActionItemListItem | PrivilegedTask)[];
  stage: ProjectStage;
  isExpanded: boolean;
  onClick: (stageId: number) => void;
  isCurrent: boolean;
  isEndedInTimeline: boolean;
  isStartedInTimeline: boolean;
}

enum StageStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

const STAGE_STATUS_CONFIG: { [key in StageStatus]: { color: string; bgColor: string; text: string } } = {
  [StageStatus.NOT_STARTED]: {
    color: '#778CA2',
    bgColor: '#FFFFFF',
    text: 'Not started'
  },
  [StageStatus.IN_PROGRESS]: {
    color: '#4D7CFE',
    bgColor: '#DBE5FF',
    text: 'In progress'
  },
  [StageStatus.COMPLETED]: {
    color: '#009A47',
    bgColor: '#CDF3DF',
    text: 'Completed'
  }
};

const StageWork = ({
  isCurrent,
  isStartedInTimeline,
  isEndedInTimeline,
  index,
  work,
  stage,
  isExpanded,
  onClick
}: StageWorkProps) => {
  const isExpandable = work.length > 0;

  const handleClick = useCallback(() => {
    if (!isExpandable) {
      return;
    }

    onClick(stage.id);
  }, [onClick, stage.id, isExpandable]);

  const stageStatus = useMemo(() => {
    if (isCurrent) {
      return StageStatus.IN_PROGRESS;
    }

    if (!isStartedInTimeline && !isEndedInTimeline) {
      if (
        work.some(
          (workItem) =>
            'taskStatus' in workItem &&
            !workItem.isArchived &&
            ![TaskStatus.Queued, TaskStatus.Cancelled].includes(workItem.taskStatus.id)
        )
      ) {
        return StageStatus.IN_PROGRESS;
      }

      return StageStatus.NOT_STARTED;
    }

    if (isStartedInTimeline && !isEndedInTimeline) {
      return StageStatus.IN_PROGRESS;
    }

    if (
      work.some(
        (workItem) =>
          ('taskStatus' in workItem &&
            !workItem.isArchived &&
            ![TaskStatus.Completed, TaskStatus.Cancelled].includes(workItem.taskStatus.id)) ||
          ('actionAssignees' in workItem && ![ActionStatus.Completed, ActionStatus.Cancelled].includes(workItem.status))
      )
    ) {
      return StageStatus.IN_PROGRESS;
    }

    return StageStatus.COMPLETED;
  }, [isCurrent, isStartedInTimeline, isEndedInTimeline, work]);

  const statusConfig = STAGE_STATUS_CONFIG[stageStatus];

  return (
    <div>
      <Stage isExpandable={isExpandable} onClick={handleClick}>
        <StageName>
          {index + 1}. {stage.name}
          {isExpandable &&
            (isExpanded ? (
              <ChevronUpIcon size="24px" color="#9C9CAA" />
            ) : (
              <ChevronDownIcon size="24px" color="#9C9CAA" />
            ))}
        </StageName>

        <Badge size={BadgeSize.Large} color={statusConfig.color} bgColor={statusConfig.bgColor}>
          {statusConfig.text.toUpperCase()}
        </Badge>
      </Stage>

      <Collapse in={isExpanded}>
        <WorkListContainer>
          <WorkItemList work={work} />
        </WorkListContainer>
      </Collapse>
    </div>
  );
};
