import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { PrivilegedTask } from '@generated/types/graphql';
import { ActionItemListItem } from '@hooks/actionItems/useActionItemList';
import { ActionItemStatus } from '@kit/components/ActionItemStatus';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { UserAvatar } from '@kit/components/UserAvatar';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { parseUtcDate } from '@utils/dates';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Archive, Calendar, User } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import { Assignee, AvatarPlaceholder, LabelBorder, Meta, Row, StatusWrapper, Title } from './styled';

interface Props {
  work: (ActionItemListItem | PrivilegedTask)[];
}

const formatDate = (date: string, isAllDay: boolean) => {
  const format = isAllDay ? 'MM/DD/YY' : 'MM/DD/YY h:mma';

  return moment(parseUtcDate(date)).format(format).replace(':00', '');
};

export const WorkItemList = ({ work }: Props) => {
  return (
    <div>
      {work.map((workItem) => {
        const isAtionItem = 'actionAssignees' in workItem;

        if (isAtionItem) {
          return <ActionItemRow key={`ai-${workItem.id}`} actionItem={workItem} />;
        }

        return <WorkOrderRow key={`wo-${workItem.id}`} workOrder={workItem} />;
      })}
    </div>
  );
};

const ActionItemRow = ({ actionItem }: { actionItem: ActionItemListItem }) => {
  const { openDrawer } = useDrawersContext();

  return (
    <Row key={`ai-${actionItem.id}`} onClick={() => openDrawer(DrawerEntity.ACTION_ITEM, actionItem.id, [])}>
      <LabelBorder />
      <Assignee>
        {actionItem.actionAssignees[0]?.contact && (
          <ContactAvatar size={20} contact={actionItem.actionAssignees[0].contact} />
        )}
      </Assignee>
      <FileUpIcon size="16px" color="#9C9CAA" />

      <Tooltip title={actionItem.title}>
        <Title>{actionItem.title}</Title>
      </Tooltip>

      <Meta>
        <Calendar size="12px" color="#9C9CAA" />
        {actionItem.dueDate ? formatDate(actionItem.dueDate, true) : 'No due date'}
      </Meta>
      <StatusWrapper>
        <ActionItemStatus status={actionItem.status} isArchived={false} />
      </StatusWrapper>
    </Row>
  );
};

const WorkOrderRow = ({ workOrder }: { workOrder: PrivilegedTask }) => {
  const { openDrawer } = useDrawersContext();

  const date = useMemo(() => {
    if (workOrder.isField) {
      if (!workOrder.taskVisitsByTaskIdConnection?.nodes?.length) {
        return 'No visits';
      }

      const visit =
        workOrder.taskVisitsByTaskIdConnection?.nodes?.find((visit) => !visit.isCompleted) ??
        workOrder.taskVisitsByTaskIdConnection?.nodes[0];

      return formatDate(visit.startDate, visit.startDateAllDay);
    }

    if (workOrder.endDate) {
      return formatDate(workOrder.endDate, workOrder.endDateAllDay);
    }

    if (workOrder.startDate) {
      return formatDate(workOrder.startDate, workOrder.startDateAllDay);
    }

    return 'No due date';
  }, [workOrder]);

  return (
    <Row isTransparent={workOrder.isArchived} onClick={() => openDrawer(DrawerEntity.WORK_ORDER, workOrder.id, [])}>
      {workOrder.firstLabel ? (
        <Tooltip title={workOrder.firstLabel.label}>
          <LabelBorder color={workOrder.firstLabel.color} />
        </Tooltip>
      ) : (
        <LabelBorder />
      )}

      <Assignee>
        {workOrder.assignee && <UserAvatar size={20} user={workOrder.assignee} />}

        {!workOrder.assignee && (
          <AvatarPlaceholder>
            <User size="16px" />
          </AvatarPlaceholder>
        )}
      </Assignee>
      <WorkOrderTypeIcon size="16px" isField={workOrder.isField} />

      {workOrder.isArchived && <Archive size="16px" color="#9C9CAA" />}

      <Tooltip title={workOrder.title}>
        <Title>
          #{workOrder.uid}
          {' · '}
          {workOrder.title}
        </Title>
      </Tooltip>

      <Meta>
        <Calendar size="12px" color="#9C9CAA" />
        {date}
      </Meta>
      <StatusWrapper>
        <WorkOrderStatus status={workOrder.taskStatus} isArchived={false} />
      </StatusWrapper>
    </Row>
  );
};
